import {
  getRequest,
  putRequest,
  createAction,
  stopLoading,
  startLoading,
  authErrorHandler
} from "openstack-uicore-foundation/lib/utils/actions";
import { getBadgeFeaturesTypes } from "./base-actions";
import {
  getAccessTokenSafely,
  retryOnNetworkError,
  retryInBackgroundOnNetworkError
} from "../utils/utils";
import {
  handleAuthAlert,
  withAlertHandler,
  alertErrorAndRethrow
} from "../utils/errorHandling";

import { exec } from "../services/wkbridge";

export const REQUEST_BADGE = "REQUEST_BADGE";
export const BADGE_RECEIVED = "BADGE_RECEIVED";
export const REQUEST_BADGE_PRINT_COUNT_INCREMENT = "REQUEST_BADGE_PRINT_COUNT_INCREMENT";
export const BADGE_PRINT_COUNT_INCREMENT_RECEIVED = "BADGE_PRINT_COUNT_INCREMENT_RECEIVED";
export const PRINT_BADGE = "PRINT_BADGE";
export const BADGE_PRINTED = "BADGE_PRINTED";
export const CLEAR_BADGE = "CLEAR_BADGE";

export const getBadge = (
  summitSlug,
  ticketId,
  viewType
) => async (dispatch, getState) => {
  const { baseState } = getState();
  const { summit, accessTokenQS } = baseState;
  const viewPath = viewType ? `/${viewType}` : "";

  if (!summit || !ticketId) throw Error("Invalid summit or ticketId. Unable to get badge for printing.");

  const accessToken = await getAccessTokenSafely(accessTokenQS);

  dispatch(startLoading());

  const params = {
    access_token: accessToken,
    expand: "ticket,ticket.owner,ticket.owner.member,ticket.owner.extra_questions",
    fields: "ticket.owner.id,ticket.owner.first_name,ticket.owner.member.first_name,ticket.owner.last_name,ticket.owner.member.last_name,ticket.owner.company,ticket.owner.extra_questions.id,ticket.owner.extra_questions.value,ticket.owner.extra_questions.value,ticket.owner.extra_questions.question_id",
    relations: "ticket.owner.extra_questions,ticket.owner.member,ticket.owner.member.none",
  };

  try {
    const badgeResponse = await retryOnNetworkError(() =>
      getRequest(
        createAction(REQUEST_BADGE),
        createAction(BADGE_RECEIVED),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/tickets/${ticketId}/badge/current${viewPath}/print`,
        withAlertHandler(authErrorHandler, handleAuthAlert),
        { viewType }
      )(params)(dispatch)
    )(dispatch);

    const { response: badge } = badgeResponse;

    const { features: badgeFeatureIds } = badge;
    const { badge_features_types: summitBadgeFeatures } = summit;

    const missingFeatures = badgeFeatureIds.filter(
      (featureId) => !summitBadgeFeatures.some((feature) => feature.id === featureId)
    );

    if (missingFeatures.length > 0) {
      console.warn(`Missing badge features: ${missingFeatures.join(", ")}. Fetching missing data...`);
      await dispatch(getBadgeFeaturesTypes(summit.id));
    }

    return badge;
  } catch(error) {
    alertErrorAndRethrow(error);
  } finally {
    dispatch(stopLoading());
  }
};

export const incrementBadgePrintCount = (
  summitSlug,
  ticketId,
  viewType,
  checkIn = true
) => async (dispatch, getState) => {
  const { baseState } = getState();
  const { summit, accessTokenQS } = baseState;
  const viewPath = viewType ? `/${viewType}` : "";

  if (!summit || !ticketId) {
    throw new Error("Invalid summit or ticketId. Unable to increment badge print count.");
  }

  const accessToken = await getAccessTokenSafely(accessTokenQS);

  const params = {
    access_token: accessToken
  };

  return retryInBackgroundOnNetworkError(() =>
    putRequest(
      createAction(REQUEST_BADGE_PRINT_COUNT_INCREMENT),
      createAction(BADGE_PRINT_COUNT_INCREMENT_RECEIVED),
     `${window.API_BASE_URL}/api/v1/summits/${summit.id}/tickets/${ticketId}/badge/current${viewPath}/print`,
      { check_in: checkIn },
      withAlertHandler(authErrorHandler, handleAuthAlert)
    )(params)(dispatch)
  )(dispatch);
};

export const printBadge = (params) => (dispatch) =>
  exec(
    createAction(PRINT_BADGE),
    createAction(BADGE_PRINTED),
    "print"
  )(params)(dispatch).then(
    (payload) => payload.response.data
  );

export const clearBadge = () => (dispatch) =>
  Promise.resolve().then(
    () => dispatch(createAction(CLEAR_BADGE)({}))
  );

