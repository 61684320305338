import Swal from "sweetalert2";
import {
    getRequest,
    putRequest,
    createAction,
    stopLoading,
    startLoading,
    authErrorHandler,
    escapeFilterValue
} from "openstack-uicore-foundation/lib/utils/actions";

import { Base64 } from 'js-base64';
import {
    getAccessTokenSafely,
    fetchPaginatedData
} from "../utils/utils";
import {
  handleAuthAlert,
  withAlertHandler,
  alertNetworkOrServerErrorAndRethrow
} from "../utils/errorHandling";

export const REQUEST_TICKET            = "REQUEST_TICKET";
export const RECEIVE_TICKET            = "RECEIVE_TICKET";
export const REQUEST_TICKETS           = "REQUEST_TICKETS";
export const RECEIVE_TICKETS           = "RECEIVE_TICKETS";
export const SET_SELECTED_TICKET       = "SET_SELECTED_TICKET";
export const CLEAR_SELECTED_TICKET     = "CLEAR_SELECTED_TICKET";
export const TICKET_UPDATED            = "TICKET_UPDATED";

export const findTicketByQRCode = (qrCode) => async (dispatch, getState) => {

    const { baseState } = getState();
    const { summit, accessTokenQS } = baseState;
    const accessToken = await getAccessTokenSafely(accessTokenQS);

    dispatch(startLoading());

    const params = {
        access_token : accessToken,
        "filter[]"   : [`is_printable==1`],
        expand       : "owner,order,ticket_type,badge,badge.type,badge.type.allowed_view_types,promo_code",
        relations    : "owner.none,order.none,promo_code.none"
    };

    let encodedQRCode = Base64.encode(qrCode);

    return getRequest(
        createAction(REQUEST_TICKET),
        createAction(RECEIVE_TICKET),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/tickets/${encodedQRCode}`,
        withAlertHandler(authErrorHandler, handleAuthAlert)
    )(params)(dispatch)
        .then((payload) => payload.response)
        .catch(alertNetworkOrServerErrorAndRethrow)
        .finally(() => dispatch(stopLoading()));
};

export const setSelectedTicket = (ticket) => (dispatch) => Promise.resolve().then(() => {
    return dispatch(createAction(SET_SELECTED_TICKET)(ticket));
})

export const clearSelectedTicket = () => (dispatch) => Promise.resolve().then(() => {
    return dispatch(createAction(CLEAR_SELECTED_TICKET)());
})

export const findTicketsByName = (firstName, lastName) => async (dispatch, getState) => {
    const { baseState: { summit, accessTokenQS } } = getState();
    const accessToken = await getAccessTokenSafely(accessTokenQS);

    dispatch(startLoading());

    const name = `${firstName} ${lastName}`;

    const params = {
        access_token : accessToken,
        page         : 1,
        per_page     : 50,
        "filter[]"   : [`owner_name==${escapeFilterValue(name)}`,`is_printable==1`],
        expand       : "owner,order,ticket_type,badge,badge.type,badge.type.allowed_view_types,promo_code",
        relations    : "owner.none,order.none,promo_code.none"
    };

    return getRequest(
        createAction(REQUEST_TICKETS),
        createAction(RECEIVE_TICKETS),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/tickets`,
        withAlertHandler(authErrorHandler, handleAuthAlert),
        { search_term: name }
    )(params)(dispatch)
        .then((payload) => payload.response.data)
        .catch(alertNetworkOrServerErrorAndRethrow)
        .finally(() => dispatch(stopLoading()));
};

export const findTicketsByEmail = (email) => async (dispatch, getState) => {
    const { baseState: { summit, accessTokenQS } } = getState();
    const accessToken = await getAccessTokenSafely(accessTokenQS);

    dispatch(startLoading());

    const params = {
        access_token : accessToken,
        page         : 1,
        per_page     : 50,
        "filter[]"   : [`owner_email==${escapeFilterValue(email)}`,`is_printable==1`],
        expand       : "owner,order,ticket_type,badge,badge.type,badge.type.allowed_view_types,promo_code",
        relations    : "owner.none,order.none,promo_code.none"
    };

    return getRequest(
        createAction(REQUEST_TICKETS),
        createAction(RECEIVE_TICKETS),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/tickets`,
        withAlertHandler(authErrorHandler, handleAuthAlert),
        { search_term: email }
    )(params)(dispatch)
        .then((payload) => payload.response.data)
        .catch(alertNetworkOrServerErrorAndRethrow)
        .finally(() => dispatch(stopLoading()));
};

export const findExternalTicketsByEmail = (email) => async (dispatch, getState) => {
    const { baseState: { summit, accessTokenQS } } = getState();
    const accessToken = await getAccessTokenSafely(accessTokenQS);

    dispatch(startLoading());

    const params = {
        access_token : accessToken,
        page         : 1,
        per_page     : 50,
        "filter[]"   : [`owner_email==${escapeFilterValue(email)}`],
        expand       : "owner,order,ticket_type,badge,badge.type,badge.type.allowed_view_types,promo_code",
        relations    : "owner.none,order.none,promo_code.none"
    };

    return getRequest(
        createAction(REQUEST_TICKETS),
        createAction(RECEIVE_TICKETS),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/tickets/external`,
        withAlertHandler(authErrorHandler, handleAuthAlert),
        { search_term: email }
    )(params)(dispatch)
        .then((payload) => payload.response.data)
        .catch(alertNetworkOrServerErrorAndRethrow)
        .finally(() => dispatch(stopLoading()));
};

export const getAllTickets = ({
    filters,
    fields,
    expand,
    relations,
    order
}) => async (dispatch, getState) => {
    const { baseState: { allTickets } } = getState();

    const idFilterMatch = Array.isArray(filters)
        ? filters.forEach(e => e.match(/id==(\d+)/))
        : filters?.match(/id==(\d+)/);

    if (idFilterMatch) {
        const ticketId = idFilterMatch[1];
        const existingTicket = allTickets.find(ticket => ticket.id == ticketId);
        if (existingTicket) return [existingTicket]; // return the ticket as an array to maintain consistency
    }

    const { baseState: { summit, accessTokenQS } } = getState();

    const accessToken = await getAccessTokenSafely(accessTokenQS);

    const params = {
        access_token: accessToken,
        per_page: 50
    };

    params["filter[]"] = Array.isArray(filters)
        ? [...filters.filter(e => !e.startsWith("is_printable")), "is_printable==1"]
        : [...(filters && !filters.startsWith("is_printable") ? [filters] : []), "is_printable==1"];
    if (fields) params["fields"] = fields;
    if (expand) params["expand"] = expand;
    if (relations) params["relations"] = relations;
    if (order) params["order"] = order;

    const endpoint = `${window.API_BASE_URL}/api/v1/summits/${summit.id}/tickets`;

    return fetchPaginatedData(
        REQUEST_TICKETS,
        RECEIVE_TICKETS,
        endpoint,
        authErrorHandler
    )(params)(dispatch);
};

export const saveExtraQuestions = (values) => async (dispatch, getState) => {
    const { baseState: { selectedTicket, accessTokenQS } } = getState();
    const accessToken = await getAccessTokenSafely(accessTokenQS);

    const normalizedEntity = {...values};
    if (!values.attendee_company.id) {
        normalizedEntity["attendee_company"] = values.attendee_company.name;
    } else {
        delete(normalizedEntity["attendee_company"]);
        normalizedEntity["attendee_company_id"] = values.attendee_company.id;
    }

    const params = {
        access_token: accessToken,
        expand: "owner, owner.extra_questions"
    };

    dispatch(startLoading());
    return putRequest(
        null,
        createAction(TICKET_UPDATED),
        `${window.API_BASE_URL}/api/v1/summits/all/orders/all/tickets/${selectedTicket.id}`,
        normalizedEntity,
        authErrorHandler
    )(params)(dispatch).then((payload) => {
        dispatch(stopLoading());
        return payload;
    }).catch(e => {
        dispatch(stopLoading());
        Swal.fire("Error", "Error saving your questions. Please retry.", "warning");
        return Promise.reject(e);
    });
};
