import React from "react";
import * as Sentry from "@sentry/react";
import { QRCodeSVG } from "qrcode.react";
import printableBadge from "../components/printable-badge";

import { BADGE_FEATURE_NOT_FOUND } from "@/utils/constants";

const DefaultBadgeViewType = "Card";

class Badge {

    constructor(summit, badge) {
        this._summit = summit;
        this._badge  = badge;
    }

    importTemplate = (templatePaths, { atIndex = -1 } = {}) => {
        const index = atIndex + 1;
        const templatePath = templatePaths[index];
        if (!templatePath)
            return ({ default: () =>
                <>
                    <p>Template not found.</p>
                    <p>Looking for template at following paths:</p>
                    <ul>
                        { templatePaths.map((path) => <li>{path}</li>) }
                    </ul>
                    <p>Badge type or view type name casing must match template path and name casing.</p>
                </>
            });
        return import(`../badge_templates/${templatePath}`)
            .catch(() => this.importTemplate(templatePaths, { atIndex: index }));
    }

    renderTemplate(summitSlug, viewTypeName = DefaultBadgeViewType) {
        const { type_id } = this._badge;

        const type = this._summit.badge_types.find( bt => bt.id === type_id)

        const badgeTemplatePaths = [
            `${summitSlug}/${type.name}/${viewTypeName}.js`,
            `${summitSlug}/${viewTypeName}.js`,
            `default/${viewTypeName}.js`
        ];

        const BadgeTemplate = React.lazy(
            () => this.importTemplate(badgeTemplatePaths)
        );

        const PrintableBadge = printableBadge(BadgeTemplate);

        return (
            <React.Suspense fallback={<div></div>}>
                <PrintableBadge badge={this} />
            </React.Suspense>
        );
    }

    getBadgeTypeId() {
        return this._badge.type_id;
    }

    getBadgeTypeName() {
        const { type_id } = this._badge;
        const type = this._summit.badge_types.find( bt => bt.id === type_id)
        return type.name;
    }

    getFirstName() {
        const { owner } = this._badge.ticket;
        let firstName = "";

        if (owner) {
            if (owner.first_name) {
                firstName = owner.first_name;
            } else if (owner.member) {
                firstName = owner.member.first_name;
            }
        }

        return firstName;
    }

    getLastName() {
        const { owner } = this._badge.ticket;
        let lastName = "";

        if (owner) {
            if (owner.last_name) {
                lastName = owner.last_name;
            } else if (owner.member) {
                lastName = owner.member.last_name;
            }
        }

        return lastName;
    }

    getFullName() {
        const { owner } = this._badge.ticket;
        let fullName = "";

        if (owner) {
            if (owner.first_name && owner.last_name) {
                fullName = `${owner.first_name} ${owner.last_name}`;
            } else if (owner.member) {
                fullName = `${owner.member.first_name} ${owner.member.last_name}`;
            }
        }

        return fullName;
    }

    getCompany() {
        const { owner } = this._badge.ticket;
        let company = "";

        if (owner?.company) {
            company = owner.company;
        }

        return company;
    }

    getTwitter() {
        const { owner } = this._badge.ticket;
        let twitter = "";

        if (owner.member && owner.member.twitter) {
            twitter = owner.member.twitter;
        }

        return twitter;
    }

    getIRC() {
        const { owner } = this._badge.ticket;
        let irc = "";

        if (owner.member && owner.member.irc) {
            irc = owner.member.irc;
        }

        return irc;
    }

    hasQRCode() {
        return this._badge.qr_code;
    }

    getQRCode(settings, level="L") {
        const { qr_code } = this._badge;
        let qr = null;

        if (qr_code) {
            qr = <QRCodeSVG value={qr_code} level={level} {...settings}/>
        }

        return qr;
    }

    getCustomQRCode(customValue, settings, level = "L") {
        return <QRCodeSVG value={customValue} level={level} {...settings}/>
    }

    getAccessLevel(accessLevelName) {
        const { type_id } = this._badge;
        const type = this._summit.badge_types.find( bt => bt.id === type_id)
        const { access_levels: access_levels_ids } = type;
        const access_levels = access_levels_ids.map(id => this._summit.badge_access_level_types.find(al => al.id === id))
        let access_level = null;

        if (access_levels) {
            access_level = access_levels.find(al => al.name == accessLevelName);
        }

        return access_level;
    }

    getFeatureIds() {
        return this._badge.features;
    }

    getAllFeatures() {
        const { features: featureIds } = this._badge;
        const { badge_features_types: badgeFeaturesTypes } = this._summit;
        return featureIds.map(featureId => badgeFeaturesTypes.find(f => f.id === featureId));
    }

    getFeature(featureName) {
        let feature = null;
        const features = this.getAllFeatures();
        if (features) {
            feature = features.find(f => f.name == featureName);
        }
        return feature;
    }

    getExtraQuestionValue(extraQuestionName) {
        const { owner: { extra_questions: extra_questions_answers } } = this._badge.ticket;
        let extraQuestionValue = null;

        if (extra_questions_answers) {
            const extraQuestion = this._summit.order_extra_questions.find( q => q.name == extraQuestionName)
            const extraQuestionAnswer = extra_questions_answers.find( a => a.question_id === extraQuestion?.id);
            if (extraQuestion && extraQuestionAnswer) {
                if (extraQuestion.values) {
                    const extraQuestionAnswerValue = extraQuestion.values.find(v => v.id == extraQuestionAnswer.value)
                    if (extraQuestionAnswerValue) extraQuestionValue = extraQuestionAnswerValue.value;
                } else {
                    extraQuestionValue = extraQuestionAnswer.value;
                }
            }
        }
        return extraQuestionValue;
    }

    getPronouns() {
        const validPronouns = ["she/her", "he/him", "they/them"];
        let pronoun = "";
        const { owner: { extra_questions: extra_questions_answers   } } = this._badge.ticket;
        const pronounQuestion = this._summit.order_extra_questions.find( q => q.name.includes("Pronoun"))
        if(!pronounQuestion) return pronoun;
        const pronounAnswer = extra_questions_answers.find( a => a.question_id === pronounQuestion?.id);
        const selectedChoice = pronounAnswer?.value;

        pronounQuestion.values?.map(v => {
            const val = v.value.toLowerCase()
            if(validPronouns?.includes(val)) {
                if(v.id?.toString() === selectedChoice) {
                    pronoun = v?.value;
                }
            }
        })

        return pronoun;
    }

    getAllowedViewTypes(){
        const type = this._summit.badge_types.find(t => parseInt(t.id) === parseInt(this._badge.type_id))
        return type.allowed_view_types.map( vt_id => this._summit.badge_view_types.find(vt => parseInt(vt.id) === parseInt(vt_id)));
    }

    getDefaultViewType() {
        return this.getAllowedViewTypes().find(vt => vt.is_default);
    }

    getViewTypeById(id){
        return this.getAllowedViewTypes().find(vt => parseInt(vt.id) === parseInt(id));
    }
}

export default Badge;
