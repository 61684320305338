import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import T from "i18n-react/dist/i18n-react";
import pLimit from "p-limit";
import {
  getAccessToken,
  initLogOut
} from "openstack-uicore-foundation/lib/security/methods";
import {
  getRequest,
  createAction,
  startLoading,
  stopLoading
} from "openstack-uicore-foundation/lib/utils/actions";

export const useForceUpdate = () => {
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
};

export const doFetch = async (url) => {
  return fetch(url)
    .then((response) => {
    if (response.ok) {
      const contentType = response.headers.get("Content-Type") || "";
      if (contentType.includes("application/json")) {
        return response.json().catch((e) => {
          return Promise.reject(
            new Error("Invalid JSON: " + e.message)
          );
        });
      }
      if (contentType.includes("text/html")) {
        return response
          .text()
          .then((html) => {
            return {
              page_type: "generic",
              html: html,
            };
          })
          .catch((e) => {
            return Promise.reject(
              new Error("HTML error: " + e.message)
            );
          });
      }
      return Promise.reject(
        new Error("Invalid content type: " + contentType)
      );
    }
    if (response.status == 404) {
      return Promise.reject(new Error("Page not found: " + url));
    }
    return Promise.reject(new Error("HTTP error: " + response.status));
  })
  .catch((e) => {
    return Promise.reject(e);
  });
}

export const getAccessTokenSafely = async (accessTokenQS) => {
  try {
    return await getAccessToken();
  }
  catch (e) {
    if (accessTokenQS) return accessTokenQS;
    console.log("log out: ", e);
    initLogOut();
  }
};

const MAX_RETRIES = 5;
const BASE_DELAY = 1000;

export const retryRequest = (
  request,
  maxRetries = MAX_RETRIES,
  baseDelay = BASE_DELAY,
  inBackground = false,
) => async (dispatch) => {
  let lastError;
  for (let retries = 1; retries <= maxRetries; retries++) {
    const delay = baseDelay * 2 ** retries; // exponential backoff
    console.log(`Retrying in ${delay} ms (${retries}/${maxRetries})...`);
    if (!inBackground) dispatch(startLoading());
    await new Promise((resolve) => setTimeout(resolve, delay));
    try {
      const response = await request();
      if (!inBackground) dispatch(stopLoading());
      return response;
    } catch (error) {
      // if http error is known, throw
      if (error.err.status) {
        console.error(`API request error: ${error.err}`);
        throw error;
      }
      // if its a network error, save last
      lastError = error;
    }
  }
  if (!inBackground) dispatch(stopLoading());
  lastError.message = T.translate("errors.max_retries_error");
  console.error(lastError);
  Sentry.captureException(lastError);
  throw lastError;
};

export const retryOnNetworkError = (
  request,
  maxRetries = MAX_RETRIES,
  baseDelay = BASE_DELAY,
  retryInBackground = false,
) => async (dispatch) => {
  try {
    return await request();
  } catch (error) {
    // if http error is known, throw
    if (error.err.status) {
      console.error(`API request error: ${error.err}`);
      throw error;
    }
    // if its a network error and retryInBackground, first resolve and then keep trying
    if (!error.err.status && retryInBackground) {
      const backgroundRetry = retryRequest(request, maxRetries, baseDelay, retryInBackground)(dispatch);
      return Promise.race([Promise.resolve(), backgroundRetry]);
    }
    // if its a network error and !retryInBackground, keep trying
    return retryRequest(request, maxRetries, baseDelay)(dispatch);
  }
};

export const retryInBackgroundOnNetworkError = (
  request,
  maxRetries = MAX_RETRIES,
  baseDelay = BASE_DELAY,
) => async (dispatch) => retryOnNetworkError(request, maxRetries, baseDelay, true)(dispatch);

export const fetchPaginatedData = (
  requestActionName,
  receiveActionName,
  endpoint,
  errorHandler,
  requestActionPayload = {},
  config = {
    useEtag: false,
    concurrency: 5, // Limit concurrent requests
    incrementalReceive: false // Collect all data and dispatch at the end
  }
) => (params = {}) => async (dispatch, state) => {
  const { useEtag, incrementalReceive, concurrency } = config;
  const limit = pLimit(concurrency);

  const receiveAction = incrementalReceive
    ? createAction(receiveActionName)
    : createAction("DUMMY");

  let allData = [];

  try {
    dispatch(startLoading());

    const initialPayload = await getRequest(
      createAction(requestActionName),
      receiveAction,
      endpoint,
      errorHandler,
      requestActionPayload,
      useEtag
    )(params)(dispatch);

    const { response: { last_page: lastPage, data: initialData } } = initialPayload;

    allData = [...initialData];

    if (lastPage === 1) {
      if (!incrementalReceive) dispatch(createAction(receiveActionName)(allData));
      return allData;
    }

    const remainingPages = Array.from({ length: lastPage - 1 }, (_, i) => i + 2);
    const requests = remainingPages.map(page =>
      limit(() =>
        getRequest(
          null, // Skip dispatching request action for subsequent pages
          receiveAction,
          endpoint,
          errorHandler,
          {}, // Empty request action payload
          useEtag
        )({ ...params, page })(dispatch)
      )
    );

    const responses = await Promise.all(requests);

    responses.forEach(payload => {
      allData = [...allData, ...payload.response.data];
    });

    if (!incrementalReceive) dispatch(createAction(receiveActionName)(allData));
    return allData;
  } catch (error) {
    console.error("Error in fetchPaginatedData:", error);
    throw error;
  } finally {
    dispatch(stopLoading());
  }
};
