import {
    getRequest,
    createAction,
    stopLoading,
    startLoading,
    authErrorHandler
} from "openstack-uicore-foundation/lib/utils/actions";
import {
    getAccessTokenSafely,
    fetchPaginatedData
} from "../utils/utils";
import {
  handleAuthAlert,
  withAlertHandler
} from "../utils/errorHandling";
import { getUnixTimestamp, range } from "../utils/methods";

export const REQUEST_SUMMITS            = "REQUEST_SUMMITS";
export const RECEIVE_SUMMITS            = "RECEIVE_SUMMITS";
export const REQUEST_BADGE_FEATURES     = "REQUEST_BADGE_FEATURES";
export const RECEIVE_BADGE_FEATURES     = "RECEIVE_BADGE_FEATURES";
export const SET_SUMMIT                 = "SET_SUMMIT";
export const CLEAR_SUMMIT               = "CLEAR_SUMMIT";
export const REQUEST_SUMMIT             = "REQUEST_SUMMIT";
export const RECEIVE_SUMMIT             = "RECEIVE_SUMMIT";
export const SET_ACCESS_TOKEN_QS        = "SET_ACCESS_TOKEN_QS";
export const GET_EXTRA_QUESTIONS        = "GET_EXTRA_QUESTIONS";
export const RECEIVE_MARKETING_SETTINGS = "RECEIVE_MARKETING_SETTINGS";

export const setAccessTokenQS = (accessToken) => (dispatch) => {
    dispatch(createAction(SET_ACCESS_TOKEN_QS)({accessToken}));
};

export const loadSummits = (includePastSummits = false) => async (dispatch, getState) => {
    const { baseState: { accessTokenQS } } = getState();
    const accessToken = await getAccessTokenSafely(accessTokenQS);

    dispatch(startLoading());

    let today = new Date();
    let currentTimestamp = getUnixTimestamp(today);
    today.setMonth(today.getMonth() - 1);
    let oneMonthAgoTimestamp = getUnixTimestamp(today);

    let filters = [];
    if (!includePastSummits) {
        filters.push(`start_date>=${oneMonthAgoTimestamp}`);
        filters.push(`end_date>=${currentTimestamp}`);
    }

    let params = {
        access_token : accessToken,
        expand: "order_extra_questions,order_extra_questions.values,badge_types,badge_view_types,badge_view_types",
        relations: "order_extra_questions,ticket_types,features,badge_features_types,badge_access_level_types,badge_view_types,badge_types",
        "filter[]": filters,
        page: 1,
        per_page: 100
    };

    return getRequest(
        createAction(REQUEST_SUMMITS),
        createAction(RECEIVE_SUMMITS),
        `${window.API_BASE_URL}/api/v1/summits/all`,
        authErrorHandler
    )(params)(dispatch).then(() =>
        dispatch(stopLoading())
    );
};

export const setSummit = (summit) => (dispatch, getState) => {
    dispatch(createAction(SET_SUMMIT)({summit}));
};

export const clearSummit = () => (dispatch) => {
    dispatch(createAction(CLEAR_SUMMIT)({}));
}

export const getSummit = (summitSlug) => async (dispatch, getState) => {
    const { baseState: { accessTokenQS } } = getState();
    const accessToken = await getAccessTokenSafely(accessTokenQS);

    dispatch(startLoading());

    const params = {
        access_token: accessToken,
        expand: "order_extra_questions,order_extra_questions.values",
    };

    return getRequest(
        createAction(REQUEST_SUMMIT),
        createAction(RECEIVE_SUMMIT),
        `${window.API_BASE_URL}/api/v1/summits/all/${summitSlug}`,
        authErrorHandler
    )(params)(dispatch).then(({response: summit}) => {
        dispatch(stopLoading())
        return summit;
    });
};

export const getBadgeFeaturesTypes = (summitId) => async (dispatch, getState) => {
  if (!summitId) throw new Error("Invalid summitId. Unable to fetch badge features.");

  const { baseState: { accessTokenQS } } = getState();
  const accessToken = await getAccessTokenSafely(accessTokenQS);

  const params = {
    access_token: accessToken,
    page: 1,
    per_page: 50,
  };

  const endpoint = `${window.API_BASE_URL}/api/v1/summits/${summitId}/badge-feature-types`;

  return fetchPaginatedData(
    REQUEST_BADGE_FEATURES,
    RECEIVE_BADGE_FEATURES,
    endpoint,
    authErrorHandler
  )(params)(dispatch);
};

export const getExtraQuestions = (summit, attendeeId) => async (dispatch, getState) => {
    const { baseState: { accessTokenQS } } = getState();
    const accessToken = await getAccessTokenSafely(accessTokenQS);

    const params = {
        access_token: accessToken,
        expand: "*sub_question_rules,*sub_question,*values",
        order: "order",
        per_page: 5
    };

    const endpoint = `${window.API_BASE_URL}/api/v1/summits/${summit.id}/attendees/${attendeeId}/allowed-extra-questions`;

    return fetchPaginatedData(
        "DUMMY",
        GET_EXTRA_QUESTIONS,
        endpoint,
        authErrorHandler
    )(params)(dispatch);
};

export const getMarketingSettings = (summitId) => async (dispatch) => {
    if (!summitId) throw new Error("Invalid summitId. Unable to fetch marketing settings.");

    const params = {
        per_page: 100
    };

    const endpoint = `${window.MARKETING_API_BASE_URL}/api/public/v1/config-values/all/shows/${summitId}`;

    return fetchPaginatedData(
        "DUMMY",
        RECEIVE_MARKETING_SETTINGS, 
        endpoint,
        withAlertHandler(authErrorHandler, handleAuthAlert)
    )(params)(dispatch);
};
